import React from "react"

import { Container, Button } from "@material-ui/core"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

import ErrorBackground from "../images/marathon-404.jpg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="error-wrapper">
      <Container className="text-center py-20 md:py-40">
        <h1 className="text-orange">404</h1>
        <p className="w-4/5 mx-auto">
          The page you are looking for doesn't exist or another error occured.
        </p>
        <div className="errorbutton-container">
          <a className="blue-button mr-5" href="/">
            <Button>Go Back Home</Button>
          </a>
          <a className="blue-button" href="/contact">
            <Button>Contact Us</Button>
          </a>
        </div>
      </Container>
    </div>
    <img
      src={ErrorBackground}
      className="error-img"
      alt="MARATHON
              MORTGAGE CORP."
    />
  </Layout>
)

export default NotFoundPage
